<template>
    <div >
      <v-card>
        <v-btn v-if="!showPromo" x-small dense rounded icon @click="stopInterval">
          <v-icon>mdi-pause</v-icon>
        </v-btn>
        <v-btn v-if="showPromo" x-small dense rounded icon @click="startInterval">
          <v-icon>mdi-play</v-icon>
        </v-btn>
        <v-tabs v-model="activeTabIndex"  fixed-tabs>
          <v-tab v-for="item in tabsContent" :key="item.value">
            {{ item.text }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTabIndex">
          <v-tab-item>
            <v-card flat>
              <v-card-title primary-title>
              <div><h3>Foydalanuvchilar soni: <span style="color: green">{{ allUsers }} ta</span></h3></div>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-card>
                      <v-card-title primary-title
                        >Foydalanuvchilar o'sishi</v-card-title
                      >
                      <v-card-text>
                        <apexchart
                          v-if="chart1.visible"
                          height="300px"
                          type="bar"
                          :options="chart1.options"
                          :series="chart1.series"
                        ></apexchart>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                  <v-card>
                    <v-card-title primary-title
                      >Hududlar bo'yicha foydalanuvchilar soni</v-card-title
                    >
                    <v-card-text>
                      <apexchart
                        v-if="chart2.visible"
                        height="300px"
                        type="bar"
                        :options="chart2.options"
                        :series="chart2.series"
                      ></apexchart>
                    </v-card-text>
                  </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-title primary-title>
                <table style="width: 50%; margin: 0 auto; border-collapse: collapse;" border="1">
                  <tbody>
                    <tr>
                      <td style="padding: 5px; background-color: #feb019; color: white; text-transform: uppercase;">Depozit</td>
                      <td style="padding: 5px; background-color: #feb019; color: white; text-align: right;">{{ paynetDepozit ? paynetDepozit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0 }} so'm</td>
                    </tr>
                    <!-- <tr>
                      <td style="padding: 5px; background-color: #44BC93; color: white; text-transform: uppercase;">To'landi (Avtomatik)</td>
                      <td style="padding: 5px; background-color: #00AF50; color: white; text-align: right;">{{ paynetTotal ? paynetTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0 }} so'm</td>
                    </tr> -->
                    <tr>
                      <td style="padding: 5px; background-color: #26b383; color: white; text-transform: uppercase;">Barcha To'landi</td>
                      <td style="padding: 5px; background-color: #26b383; color: white; text-align: right;">{{ amountPaymentPromocodes ? amountPaymentPromocodes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0 }} so'm / {{ ortacha.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td style="padding: 5px; background-color: #2F74B5; color: white; text-transform: uppercase;"">Soni</td>
                      <td style="padding: 5px; background-color: #2F74B5; color: white; text-align: right;">{{allPaymentPromocodes ? allPaymentPromocodes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0}} ta</td>
                    </tr>
                  </tbody>
                </table>
                <!-- <div><h3>Paynet miqdori: <span style="color: green">{{ amountPaymentPromocodes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} so'm</span> / <span style="color: red">{{ allPaymentPromocodes }} ta</span></h3></div> -->
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-card>
                      <v-card-title primary-title
                        >To'lovlar miqdori bo'yicha</v-card-title
                      >
                      <v-card-text>
                        <apexchart
                          v-if="chart4.visible"
                          height="300px"
                          type="bar"
                          :options="chart4.options"
                          :series="chart4.series"
                        ></apexchart>
                      </v-card-text>
                    </v-card>
                  </v-col>
            
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-title primary-title>
                <table style="width: 50%; margin: 0 auto; border-collapse: collapse;" border="1">
                  <tbody>
                    <tr>
                      <td style="padding: 5px; background-color: #008ffb; color: white; text-transform: uppercase;""> Jami</td>
                      <td style="padding: 5px; background-color: #008ffb; color: white; text-align: right;">{{ allPromos ? allPromos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0 }} ta</td>
                    </tr>
                    <!-- <tr>
                      <td style="padding: 5px; background-color: red; color: white; text-transform: uppercase;"">Foydalanildi</td>
                      <td style="padding: 5px; background-color: red; color: white; text-align: right;">{{ allUsedPromos ? allUsedPromos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0 }} ta</td>
                    </tr> -->
                    <tr>
                      <td style="padding: 5px; background-color: #00e396; color: white; text-transform: uppercase;">Pulli</td>
                      <td style="padding: 5px; background-color: #00e396; color: white; text-align: right;">{{ allPaymentPromocodes ? allPaymentPromocodes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0 }}  ta</td>
                    </tr>
                    <tr>
                      <td style="padding: 5px; background-color: #feb019; color: white; text-transform: uppercase;">Mega Aksiya</td>
                      <td style="padding: 5px; background-color: #feb019; color: white; text-align: right;">{{ allNulledPromocodes ? allNulledPromocodes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0 }}  ta</td>
                    </tr>
                  </tbody>
                </table>
                <!-- <div><h3>Promokodlar soni: <span style="color: orange">{{ allMonthPromos }}</span> / <span style="color: green">{{ allUsedPromos }}</span> / <span style="color: red">{{ allPromos }}</span></h3></div> -->
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-card>
                      <v-card-title primary-title
                        >Promokodlar bo'yicha</v-card-title
                      >
                      <v-card-text>
                        <apexchart
                          v-if="chart3.visible"
                          height="300px"
                          type="bar"
                          :options="chart3.options"
                          :series="chart3.series"
                        ></apexchart>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                  <v-card>
                    <v-card-title primary-title
                      >Viloyatlar bo'yicha</v-card-title
                    >
                    <v-card-text>
                      <apexchart
                        v-if="chart6.visible"
                        height="300px"
                        type="bar"
                        :options="chart6.options"
                        :series="chart6.series"
                      ></apexchart>
                    </v-card-text>
                  </v-card>
                  </v-col>
                  <v-col cols="6">
                  <v-card>
                    <v-card-title primary-title
                      >Tumanlar bo'yicha</v-card-title
                    >
                    <v-card-text>
                      <apexchart
                        v-if="chart7.visible"
                        type="bar"
                        :options="chart7.options"
                        :series="chart7.series"
                      ></apexchart>
                    </v-card-text>
                  </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          
        </v-tabs-items>
      </v-card>
    </div>
  </template>
  
  <script>
  import VueApexCharts from "vue-apexcharts";
  const axios = require("axios").default;
  
  export default {
    components: {
      apexchart: VueApexCharts,
    },
    data() {
      return {
        itemBirthday: [],
        countBirthday: 0,
        allUsers: 0,
        allPromos: 0,
        allUsedPromos: 0,
        showPromo: false,
        amountPaymentPromocodes: 0,
        allPaymentPromocodes: 0,
        allNulledPromocodes: 0,
        allGiftedPromocodes: 0,
        allMonthPromos: 0,
        allOriginalpromos: 0,
        allUsedOriginalPromos: 0,
        originalPromocodesCount: 0,
        ortacha: 0,
        allMonthPromos: 0,
        tabsContent: [
          {
            value: 0,
            text: "Foydalanuvchilar",
          },
          {
            value: 1,
            text: "To'lovlar",
          },
          {
            value: 2,
            text: "Promokodlar",
          },
        ],
        activeTabIndex: 0,
        charts1: {
          visible: false,
        },
        charts2: {
            visible: false,
        },
        charts3: {
            visible: false,
        },
        charts4: {
            visible: false,
        },
        charts5: {
            visible: false,
        },
        charts6: {
            visible: false,
        },
        charts7: {
            visible: false,
        },

      };
    },
    computed: {
      chart1() {
        return this.charts1;
      },
      chart2() {
        return this.charts2;
      },
      chart3() {
        return this.charts3;
      },
      chart4() {
        return this.charts4;
      },
      chart5() {
        return this.charts5;
      },
      chart6() {
        return this.charts6;
      },
      chart7() {
        return this.charts7;
      },
    },
    methods: {
      getChart() {
        this.$http
          .get("/getDashboard50/")
          .then((response) => {
            this.allUsers = response.data[3]['allusers'];
            this.allPromos = response.data[3]['allpromocodes'];
            this.allUsedPromos = response.data[3]['allusedpromocodes'];
            this.allMonthPromos = response.data[3]['allmonthpromocodes'];
            this.amountPaymentPromocodes = response.data[3]['amountpaymentpromocodes'];
            this.allPaymentPromocodes = response.data[3]['allpaymentpromocodes'];
            this.allNulledPromocodes = response.data[3]['allnulledpromocodes'];
            this.allGiftedPromocodes = response.data[3]['allgiftedpromocodes'];
            this.allOriginalpromos = response.data[3]['alloriginalpromocodes'];
            this.allUsedOriginalPromos = response.data[3]['allusedoriginalpromocodes'];
            this.originalPromocodesCount = response.data[3]['originalpromocodescount'];
            this.ortacha = this.amountPaymentPromocodes / this.allPaymentPromocodes;
              this.charts1 = {
                visible: true,
                options: {
                  chart: {
                    id: "vuechart-example",
                  },
                  xaxis: {
                    categories: response.data[0].map((v) => v.registration_day),
                  },
                  plotOptions: {
                    bar: {
                      barHeight: "100%",
                      borderRadius: 5,
                      distributed: false,
                      horizontal: false,
                      dataLabels: {
                        position: "top",
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    offsetY: -20,
                    style: {
                      fontSize: "12px",
                      colors: ["#304758"],
                    },
                  },
                },
                series: [
                  {
                    name: "Soni",
                    data: response.data[0].map((v) => v.new_user_count),
                  },
                ],
              };
              this.charts2 = {
              visible: true,
              animations: {
                enabled: true,
                easing: "easeinout",
                speed: 800,
                animateGradually: {
                  enabled: true,
                  delay: 150,
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 350,
                },
              },
              options: {
                chart: {
                  type: "bar",
                  height: 380,
                },
                plotOptions: {
                  bar: {
                    barHeight: "100%",
                    distributed: true,
                    horizontal: true,
                    dataLabels: {
                      position: "top",
                    },
                  },
                },
                dataLabels: {
                  enabled: true,
                  offsetX: 40,
                  style: {
                    fontSize: "12px",
                    colors: ["#304758"],
                  },
                },
                stroke: {
                  width: 1,
                  colors: ["#fff"],
                },
                xaxis: {
                  categories: response.data[1].map((v) => v.region_name),
                },
                yaxis: {
                  labels: {
                    show: true,
                  },
                },
              },
              series: [
                {
                  name: "Soni",
                  data: response.data[1].map((v) => v.user_count),
                },
              ],
              };
              this.charts3 = {
                visible: true,
                options: {
                  chart: {
                    id: "vuechart-example",
                  },
                  xaxis: {
                    categories: response.data[2]['total'].map((v) => v.registration_day),
                  },
                  plotOptions: {
                    bar: {
                      barHeight: "100%",
                      borderRadius: 5,
                      distributed: false,
                      horizontal: false,
                      dataLabels: {
                        position: "top",
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    offsetY: -20,
                    style: {
                      fontSize: "12px",
                      colors: ["#304758"],
                    },
                  },
                },
                series: [
                  {
                    name: "Jami",
                    data: response.data[2]['total'].map((v) => v.promocode_count),
                  },
                  {
                    name: "Pulli",
                    data: response.data[2]['payed'].map((v) => v.promocode_count),
                  },
                  {
                    name: "Yutuqsiz",
                    data: response.data[2]['nulled'].map((v) => v.promocode_count),
                  },
                ],
              };
              this.charts4 = {
                visible: true,
                options: {
                  chart: {
                    id: "vuechart-example",
                  },
                  xaxis: {
                    categories: response.data[3]['payment'].map((v) => v.registration_day),
                  },
                  plotOptions: {
                    bar: {
                      barHeight: "100%",
                      borderRadius: 5,
                      distributed: false,
                      horizontal: false,
                      dataLabels: {
                        position: "top"
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    offsetY: -20,
                    style: {
                      fontSize: "12px",
                      colors: ["#304758"],
                    },
                  },
                  colors:['#26B383']
                },
                series: [
                  {
                    name: "Jami",
                    data: response.data[3]['payment'].map((v) => v.discount),
                  },
                ],
              };
              this.charts6 = {
              visible: true,
              animations: {
                enabled: true,
                easing: "easeinout",
                speed: 800,
                animateGradually: {
                  enabled: true,
                  delay: 150,
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 350,
                },
              },
              options: {
                chart: {
                  type: "bar",
                  height: 380,
                },
                plotOptions: {
                  bar: {
                    barHeight: "100%",
                    distributed: true,
                    horizontal: true,
                    dataLabels: {
                      position: "top",
                    },
                  },
                },
                dataLabels: {
                  enabled: true,
                  offsetX: 40,
                  style: {
                    fontSize: "12px",
                    colors: ["#304758"],
                  },
                },
                stroke: {
                  width: 1,
                  colors: ["#fff"],
                },
                xaxis: {
                  categories: response.data[3]['originalpromoregion'].map((v) => v.region_name),
                },
                yaxis: {
                  labels: {
                    show: true,
                  },
                },
              },
              series: [
                {
                  name: "Soni",
                  data: response.data[3]['originalpromoregion'].map((v) => v.promo_count),
                },
              ],
              };
              this.charts7 = {
              visible: true,
              animations: {
                enabled: true,
                easing: "easeinout",
                speed: 800,
                animateGradually: {
                  enabled: true,
                  delay: 150,
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 350,
                },
              },
              options: {
                chart: {
                  type: "bar",
                  height: 380,
                },
                plotOptions: {
                  bar: {
                    barHeight: "100%",
                    distributed: true,
                    horizontal: true,
                    dataLabels: {
                      position: "top",
                    },
                  },
                },
                dataLabels: {
                  enabled: true,
                  offsetX: 40,
                  style: {
                    fontSize: "12px",
                    colors: ["#304758"],
                  },
                },
                stroke: {
                  width: 1,
                  colors: ["#fff"],
                },
                xaxis: {
                  categories: response.data[3]['originalpromodistric'].map((v) => v.district_name),
                },
                legend: {
                    show: false // Hides the legend
                },
                yaxis: {
                  labels: {
                    show: true,
                  },
                },
              },
              series: [
                {
                  name: "Soni",
                  data: response.data[3]['originalpromodistric'].map((v) => v.promo_count),
                },
              ],
              };
            // window.dispatchEvent(new Event("resize"));
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getCount(){
        this.$http
          .get("/getDashboardPaynet")
          .then(({ data }) => {
            this.paynetDepozit = data.depozit;
            this.paynetTotal = data.total.total_sum;
            console.log(this.paynetTotal);
            this.loaded = true;
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
      },
      startInterval() {
        this.showPromo=false;
        if (Array.isArray(this.tabsContent) && this.tabsContent.length > 0) {
          // Set interval to switch tabs every 20 seconds
          this.interval = setInterval(() => {
            // Calculate the next tab index
            this.activeTabIndex = (this.activeTabIndex + 1) % this.tabsContent.length;
          }, 20000); // 20 seconds
        } else {
          console.error("Tabs array is empty or not an array.");
        }
      },
      stopInterval() {
        this.showPromo=true;
        // Stop the interval timer when needed (e.g., when component is destroyed)
        clearInterval(this.interval);
      }

    },
    mounted() {
      this.getChart();
      this.getCount();
      this.startInterval();
      setInterval(() => {
        this.getChart();
        this.getCount();
      }, 60000);
    },
    watch: {
      switch1(newValue){
        //called whenever switch1 changes
        console.log(newValue);
      }
    }
  };
  </script>
  
  <style>
  .heightFull {
  height: 100%;
  background: #fff;
}
  </style>
  