<template>
  <div>
    <v-layout wrap justify-center align-center>
      <v-flex xs12 sm12 md8 class="px-4">
        <div class="d-flex align-center justify-center">
          <v-spacer></v-spacer>
          <h2
            class="text-center mr-5 font-weight-bold light-blue--text text--darken-4 my-4"
          >
            {{ today }}
          </h2>

          <v-spacer></v-spacer>
          <v-btn color="success" class="ma-2"  large @click="sendMessage()">
            <v-icon>mdi-send</v-icon> Xabar yuborish
          </v-btn>
          <v-btn color="primary" large @click="newAction()">
            Yangi qo'shish
          </v-btn>
        </div>

        <v-progress-linear
          v-if="!loaded"
          color="primary"
          indeterminate
          rounded
          height="4"
        ></v-progress-linear>

        <div class="d-flex justify-space-around">
          <div class="d-flex flex-column align-center">
            <p class="income-text mb-n1" style="font-size: 32px">
              {{ userscount }} ta
            </p>
            <p class="income-text">Foydalanuvchilar</p>
          </div>
          <div class="d-flex flex-column align-center">
            <p class="primary-text mb-n1" style="font-size: 32px">
              {{ adminuserscount }} ta
            </p>
            <p class="primary-text">Adminlar</p>
          </div>
          <div class="d-flex flex-column align-center">
            <p class="today-text mb-n1" style="font-size: 32px">
              {{ alluserscount }} ta
            </p>
            <p class="today-text">Jami</p>
          </div>
        </div>
        <div class="d-flex flex-wrap justify-center">
          <v-card class="ma-2" xs12 sm8 md6 min-width="100%">
            <v-tabs v-model="tab" background-color="primary" dark fixed-tabs>
              <v-tab v-for="item in tabsContent" :key="item.value">
                {{ item.text }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card flat>
                  <v-data-table
                    :headers="headers"
                    :items="users"
                    :options="dataTableOptions"
                    class="elevation-1"
                  >
                  <template v-slot:item.id="{item}">
                      {{
                        users
                        .map(function(x) {
                        return x.id;
                        })
                        .indexOf(item.id) + 1
                      }}
                    </template>
                  <template v-slot:item.updated_at="{item}">
                      
                      {{ item.updated_at ? item.updated_at.substr(0,10) : ''}}  
                      {{ item.updated_at ? item.updated_at.substr(11,5) : ''}}
                  </template>
                  <template v-slot:item.role_id="{item}">
                    {{ item.role_id == 1 ? 'Admin' : 'Paynet' }}
                  </template>
                  <!-- <template v-slot:item.actions="{ item }">
                      <v-btn color="red" small text @click="deleteUser(item)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                  </template> -->
                    <!-- <template v-slot:item.actions="{ item }">
                      <v-btn
                        color="success"
                        small
                        text
                        v-if="!item.status"
                        @click="changeStatus(item)"
                      >
                        <v-icon>mdi-check-bold</v-icon>
                      </v-btn>
                    </template> -->
                    </v-data-table
                  >
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-data-table
                    :headers="headers"
                    :items="admins"
                    :options="dataTableOptions"
                    class="elevation-1"
                    >
                    <template v-slot:item.id="{item}">
                      {{
                        admins
                        .map(function(x) {
                        return x.id;
                        })
                        .indexOf(item.id) + 1
                      }}
                    </template>
                    <template v-slot:item.updated_at="{item}">
                        
                        {{ item.updated_at ? item.updated_at.substr(0,10) : ''}}  
                        {{ item.updated_at ? item.updated_at.substr(11,5) : ''}}
                    </template>
                    <template v-slot:item.role_id="{item}">
                      {{ item.role_id == 1 ? 'Admin' : 'Paynet' }}
                    </template>
                    
                    </v-data-table
                  >
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </div>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="addMoneyModal"
      persistent
      max-width="450px"
      @keydown.esc="addMoneyModal = false"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Add Action</span>
          <v-spacer></v-spacer>
          <v-btn color="error" x-small fab @click="addMoneyModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <form
              @submit.prevent.stop="register"
              ref="form"
              lazy-validation
              class="mt-2"
            >
              <v-text-field
                v-model="name"
                @blur="$v.name.$touch()"
                tabindex="1"
                type="text"
                label="Name"
                autofocus
                hide-details
                :class="nameErrors.length && 'input-errors'"
                :color="nameErrors.length ? 'error' : null"
              >
                <template v-slot:prepend-inner>
                  <icon-base
                    width="16"
                    height="16"
                    class="mr-1"
                    :class="iconNameStyle"
                  >
                    <icon-user />
                  </icon-base>
                </template>
                <template v-slot:append v-if="name">
                  <v-btn small icon tabindex="-1" @click="name = ''">
                    <icon-base width="18" height="18">
                      <icon-close />
                    </icon-base>
                  </v-btn>
                </template>
              </v-text-field>
              <div class="v-text-field__details mt-1 ml-6">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">{{ nameErrors[0] }}</div>
                  </div>
                </div>
              </div>

              <v-text-field
                v-model="email"
                @blur="$v.email.$touch()"
                tabindex="2"
                type="email"
                label="Email"
                hide-details
                :class="emailErrors.length && 'input-errors'"
                :color="emailErrors.length ? 'error' : null"
              >
                <template v-slot:prepend-inner>
                  <icon-base
                    width="16"
                    height="16"
                    class="mr-1"
                    :class="iconMailStyle"
                  >
                    <icon-mail />
                  </icon-base>
                </template>
                <template v-slot:append v-if="email">
                  <v-btn small icon tabindex="-1" @click="email = ''">
                    <icon-base width="18" height="18">
                      <icon-close />
                    </icon-base>
                  </v-btn>
                </template>
              </v-text-field>
              <div class="v-text-field__details mt-1 ml-6">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">{{ emailErrors[0] }}</div>
                  </div>
                </div>
              </div>

              <v-text-field
                v-model="password"
                @blur="$v.password.$touch()"
                class="mt-2"
                tabindex="3"
                :type="passwordType"
                label="Password"
                hide-details
                :class="passwordErrors.length && 'input-errors'"
                :color="passwordErrors.length ? 'error' : null"
              >
                <template v-slot:prepend-inner>
                  <icon-base
                    width="16"
                    height="16"
                    class="mr-1"
                    :class="iconLockStyle"
                  >
                    <icon-lock />
                  </icon-base>
                </template>
                <template v-slot:append>
                  <v-btn
                    v-if="password"
                    tabindex="-1"
                    small
                    icon
                    class="mr-n1"
                    @click="typePassword = !typePassword"
                  >
                    <icon-base width="18" height="18" class="text-subtitle">
                      <icon-eye />
                    </icon-base>
                  </v-btn>
                  <v-btn
                    v-if="password"
                    small
                    icon
                    class="mr-n1"
                    @click="password = ''"
                    tabindex="-1"
                  >
                    <icon-base width="18" height="18" class="text-subtitle">
                      <icon-close />
                    </icon-base>
                  </v-btn>
                </template>
              </v-text-field>
              <div class="v-text-field__details mt-1 ml-6">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ passwordErrors[0] }}
                    </div>
                  </div>
                </div>
              </div>

              <v-text-field
                v-model="confirmPassword"
                @blur="$v.confirmPassword.$touch()"
                class="mt-2"
                tabindex="4"
                :type="confirmPasswordType"
                label="Confirm Password"
                hide-details
                :class="confirmPasswordErrors.length && 'input-errors'"
                :color="confirmPasswordErrors.length ? 'error' : null"
              >
                <template v-slot:prepend-inner>
                  <icon-base
                    width="16"
                    height="16"
                    class="mr-1"
                    :class="iconLockStyle"
                  >
                    <icon-lock />
                  </icon-base>
                </template>
                <template v-slot:append>
                  <v-btn
                    v-if="confirmPassword"
                    tabindex="-1"
                    small
                    icon
                    class="mr-n1"
                    @click="typeConfirmPassword = !typeConfirmPassword"
                  >
                    <icon-base width="18" height="18" class="text-subtitle">
                      <icon-eye />
                    </icon-base>
                  </v-btn>
                  <v-btn
                    v-if="confirmPassword"
                    small
                    icon
                    class="mr-n1"
                    @click="confirmPassword = ''"
                    tabindex="-1"
                  >
                    <icon-base width="18" height="18" class="text-subtitle">
                      <icon-close />
                    </icon-base>
                  </v-btn>
                </template>
              </v-text-field>
              <div class="v-text-field__details mt-1 ml-6">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ confirmPasswordErrors[0] }}
                    </div>
                  </div>
                </div>
              </div>

              <v-btn
                block
                :loading="loading"
                :disabled="loading"
                type="submit"
                depressed
                class="mt-6 primary"
                >Register</v-btn
              >
            </form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { email, minLength, required, sameAs } from "vuelidate/lib/validators";
import messagesMixin from "@/mixins/messagesMixin";

export default {
  name: "Todos",

  mixins: [messagesMixin, validationMixin],

  data() {
    return {
      today: "",
      tab: "",
      user: null,
      actions: [],
      users: [],
      confirmPassword: "",
      name: "",
      email: "",
      loader: null,
      loading: false,
      password: "",
      typeConfirmPassword: true,
      typePassword: true,
      dataTableOptions: {
          page: 1,
          itemsPerPage: 50,
        },
      admins: [],
      addBalanceModal: false,
      addMoneyModal: false,
      showBalanceStatus: false,
      balance: null,
      loaded: false,
      todayCount: 0,
      checkedCount: 0,
      unCheckedCount: 0,
      alluserscount: 0,
      adminuserscount: 0,
      userscount: 0,
      tabsContent: [
        {
          value: 0,
          text: "Foydalanuvchilar",
        },
        {
          value: 1,
          text: "Adminlar",
        },
      ],

      expDates: [
        {
          value: 192,
          text: "19 soat (2)",
        },
        {
          value: 32,
          text: "3 kun (2)",
        },
        {
          value: 72,
          text: "7 kun (2)",
        },
        {
          value: 142,
          text: "14 kun (2)",
        },
        {
          value: 282,
          text: "28 kun (2)",
        },
        {
          value: 194,
          text: "19 soat (4)",
        },
        {
          value: 34,
          text: "3 kun (4)",
        },
        {
          value: 74,
          text: "7 kun (4)",
        },
        {
          value: 144,
          text: "14 kun (4)",
        },
        {
          value: 284,
          text: "28 kun (4)",
        },
      ],
      money: {},
      headers: [
        {
          text: "T/r",
          sortable: false,
          value: "id",
          class: "primary--text",
        },
        {
          text: "FIO",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Email",
          sortable: false,
          value: "email",
          class: "primary--text",
        },
        {
          text: "Role",
          sortable: false,
          value: "role_id",
          class: "primary--text",
        },
        {
          text: "Yangilangan",
          sortable: false,
          value: "updated_at",
          class: "primary--text",
        },
        
        {
          text: "Amallar",
          value: "actions",
          sortable: false,
          class: "primary--text",
          align: "center",
        },
      ],
    };
  },

  mounted() {
    let date = new Date().toLocaleDateString();
    // let time = new Date().toLocaleTimeString();
    this.today = date;
    this.getUser();
  },
  computed: {
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      !this.$v.confirmPassword.required &&
        errors.push("Confirm Password is required");
      !this.$v.confirmPassword.sameAsPassword &&
        errors.push("Both passwords must match");
      return errors;
    },

    confirmPasswordType() {
      return this.typeConfirmPassword ? "password" : "text";
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid email");
      !this.$v.email.required && errors.push("Email is required");
      return errors;
    },

    iconLockStyle() {
      return this.password && !this.passwordErrors.length
        ? "primary--text"
        : this.passwordErrors.length
        ? "error--text text--lighten-1"
        : "grey--text text--darken-1";
    },

    iconMailStyle() {
      return this.email && !this.emailErrors.length
        ? "primary--text"
        : this.emailErrors.length
        ? "error--text text--lighten-1"
        : "grey--text text--darken-1";
    },

    iconNameStyle() {
      return this.name && !this.nameErrors.length
        ? "primary--text"
        : this.nameErrors.length
        ? "error--text text--lighten-1"
        : "grey--text text--darken-1";
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.minLength &&
        errors.push("Name must be at least 2 characters long");
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Password must be at least 6 characters long");
      !this.$v.password.required && errors.push("Password is required");
      return errors;
    },

    passwordType() {
      return this.typePassword ? "password" : "text";
    },

    userAvatar() {
      return localStorage.getItem("userAvatar");
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      this.loader = null;
    },
  },

  validations: {
    confirmPassword: { required, sameAsPassword: sameAs("password") },
    email: { required, email },
    name: { required, minLength: minLength(2) },
    password: { required, minLength: minLength(6) },
  },

  methods: {
    // addArray() {
    //   let arr = [];
    //   for (let i = 1; i < 6; i++) {
    //     if (eval("this.row" + i).length > 0) {
    //       arr.push(eval("this.row" + i));
    //     }
    //   }
    //   // arr.push(this.row, this.row2);
    //   this.checked = arr;
    // },
    register() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loader = "loading";

      this.$http
        .post("register", {
          email: this.email,
          name: this.name,
          password: this.password,
          password_confirmation: this.confirmPassword,
        })
        .then((data) => {
          this.showSuccessMessage("Foydalanuvchi qo'shildi", 3000);
          this.getUser();
          this.addMoneyModal = false;
        })
        .catch((error) => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            this.showCustomErrorMessage(
              Object.values(error.response.data.errors).flat().join(" "),
              4000
            );
          } else {
            this.showErrorMessage();
          }

          console.log(error);
        })
        .finally(() => {
          this.loader = "loading";
        });
    },
    getUser() {
      this.$http
        .post("/webUsersList", {
          pagination: this.dataTableOptions,
        })
        .then(({ data }) => {
          let date = new Date().toISOString().slice(0, 10);
          this.alluserscount = data.data.length;
          this.users = data.data.filter((v) => {
            if (v.role_id == 2) {
              return v;
            }
          });
          this.userscount = this.users.length;
          this.admins = data.data.filter((v) => {
            if (v.role_id == 1) {
              return v;
            }
          });
          this.adminuserscount = this.admins.length;
          
          this.loaded = true;
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessage();
        });
    },
    newAction() {
      this.money = {
        id: Date.now(),
        name: null,
        reg_date: null,
        exp_date: null,
      };
      this.addMoneyModal = true;
    },
    addAction() {
      this.$http
        .post("/addAction", {
          money: this.money,
        })
        .then(({ data }) => {
          this.getUser();
          this.addMoneyModal = false;
          this.showSuccessMessage("Malumotlar qo'shildi", 3000);
        })
        .catch((error) => {
          console.log(error);
          // this.showErrorMessage();
        });
    },

    deleteUser(item) {
      this.$http
        .get("/deleteUser/" + item.id)
        .then(({ data }) => {
          this.getUser();
          this.showSuccessMessage("User has been deleted");
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessage();
        });
    },
    sendMessage(item) {
      this.$http
        .get("/sendMessage")
        .then(({ data }) => {
          // this.getUser();

          this.showSuccessMessage("Xabaringiz foydalanuvchilarga yuborildi");
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessage();
        });
    },

    edit(item) {
      this.addMoneyModal = true;
      this.money = Object.assign({}, item);
    },
    changeStatus(item) {
      this.$http
        .post("/changeStatus", {
          item: item,
        })
        .then(({ data }) => {
          this.getUser();

          this.showSuccessMessage("Action has been deleted");
        })
        .catch((error) => {
          this.showErrorMessage();
        });
    },
  },
};
</script>

<style lang="css" scoped>
.input-errors >>> .v-input__control > .v-input__slot:before {
  border-color: #f56c6c !important;
}
.income-text {
  color: green;
}
.primary-text {
  color: blue;
}
.expance-text {
  color: red;
}
.today-text {
  color: orange;
}
.header-text {
  color: white;
  background-color: rgb(223, 221, 221);
}
</style>
