<template>
  <div>
    <v-layout wrap justify-center align-center>
      <v-flex xs12 sm12 md8 class="px-4">
        <div class="d-flex align-center justify-center">
          <v-spacer></v-spacer>
          <h2
            class="text-center mr-5 font-weight-bold light-blue--text text--darken-4 my-4"
          >
            {{ today }}
          </h2>

          <v-spacer></v-spacer>
          <v-btn color="success" class="ma-2"  large @click="sendMessage()">
            <v-icon>mdi-send</v-icon> Xabar yuborish
          </v-btn>
          <v-btn color="success" class="ma-2"  large @click="refreshPromoCount()">
            <v-icon>mdi-refresh</v-icon> Promokod
          </v-btn>
        </div>

        <v-progress-linear
          v-if="!loaded"
          color="primary"
          indeterminate
          rounded
          height="4"
        ></v-progress-linear>

        <div class="d-flex justify-space-around">
          <div class="d-flex flex-column align-center">
            <p class="income-text mb-n1" style="font-size: 32px">
              {{ userscount }} ta
            </p>
            <p class="income-text">Foydalanuvchilar</p>
          </div>
          <div class="d-flex flex-column align-center">
            <p class="primary-text mb-n1" style="font-size: 32px">
              {{ adminuserscount }} ta
            </p>
            <p class="primary-text">Adminlar</p>
          </div>
          <div class="d-flex flex-column align-center">
            <p class="today-text mb-n1" style="font-size: 32px">
              {{ alluserscount }} ta
            </p>
            <p class="today-text">Jami</p>
          </div>
        </div>
        <div class="d-flex flex-wrap justify-center">
          <v-card class="ma-2" xs12 sm8 md6 min-width="100%">
            <v-tabs v-model="tab" background-color="primary" dark fixed-tabs>
              <v-tab v-for="item in tabsContent" :key="item.value">
                {{ item.text }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card flat>
                  <v-data-table
                    :headers="headers"
                    :items="users"
                    :server-items-length="server_items_length"
                    :options.sync="dataTableOptions"
                    :disable-pagination="true"
                    @update:page="updatePage"
                    @update:items-per-page="updatePerPage"
                    class="elevation-1"
                    :footer-props="{
                        itemsPerPageOptions: [50, 100, -1],
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-arrow-left',
                        nextIcon: 'mdi-arrow-right',
                      }"
                  >
                  <template v-slot:item.id="{item}">
                      {{
                        users
                        .map(function(x) {
                        return x.id;
                        })
                        .indexOf(item.id) + from
                      }}
                    </template>
                  <template v-slot:item.region="{item}">
                    {{ item.region.name }}
                  </template>
                    <!-- <template v-slot:item.actions="{ item }">
                      <v-btn
                        color="success"
                        small
                        text
                        v-if="!item.status"
                        @click="changeStatus(item)"
                      >
                        <v-icon>mdi-check-bold</v-icon>
                      </v-btn>
                    </template> -->
                    </v-data-table
                  >
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-data-table
                    :headers="headers"
                    :items="admins"
                    :options="dataTableOptions"
                    class="elevation-1"
                    >
                    <template v-slot:item.id="{item}">
                      {{
                        admins
                        .map(function(x) {
                        return x.id;
                        })
                        .indexOf(item.id) + 1
                      }}
                    </template>
                    <template v-slot:item.region="{item}">
                      {{ item.region.name }}
                    </template>
                    <!-- <template v-slot:item.actions="{ item }">
                      <v-btn color="blue" small text @click="edit(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn color="red" small text @click="deleteAction(item)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template> -->
                    </v-data-table
                  >
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </div>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="addMoneyModal"
      persistent
      max-width="450px"
      @keydown.esc="addMoneyModal = false"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Add Action</span>
          <v-spacer></v-spacer>
          <v-btn color="error" x-small fab @click="addMoneyModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="pt-0" cols="12">
                <v-text-field
                  v-model="money.name"
                  label="Name"
                  type="text"
                  solo
                  dense
                  clearable
                ></v-text-field>
              </v-col>
              <v-col class="pt-0" cols="12">
                <v-text-field
                  v-model="money.reg_date"
                  label="Kelgan sana"
                  type="datetime-local"
                  outlined
                  dense
                  clearable
                ></v-text-field>
              </v-col>
              <v-col class="pt-0" cols="12">
                <v-select
                  v-model="money.exp_date"
                  :items="expDates"
                  label="Type"
                  multiple
                  chips
                  solo
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="green" dark @click="addAction()">Save</v-btn>
          <!-- <v-btn color="green" dark @click="addArray()">Push</v-btn> -->
          <!--                        <v-btn color="red darken-1" dark @click="onClickOutside">{{ $t('close') }}</v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { maxLength, required } from "vuelidate/lib/validators";
import messagesMixin from "@/mixins/messagesMixin";

export default {
  name: "Todos",

  mixins: [messagesMixin, validationMixin],

  data() {
    return {
      today: "",
      tab: "",
      user: null,
      actions: [],
      users: [],
      dataTableOptions: {
          page: 1,
          itemsPerPage: 50,
        },
      page: 1,
      server_items_length: -1,
      from: 0,
      admins: [],
      addBalanceModal: false,
      addMoneyModal: false,
      showBalanceStatus: false,
      balance: null,
      loaded: false,
      todayCount: 0,
      checkedCount: 0,
      unCheckedCount: 0,
      alluserscount: 0,
      adminuserscount: 0,
      userscount: 0,
      tabsContent: [
        {
          value: 0,
          text: "Foydalanuvchilar",
        },
        {
          value: 1,
          text: "Adminlar",
        },
      ],

      expDates: [
        {
          value: 192,
          text: "19 soat (2)",
        },
        {
          value: 32,
          text: "3 kun (2)",
        },
        {
          value: 72,
          text: "7 kun (2)",
        },
        {
          value: 142,
          text: "14 kun (2)",
        },
        {
          value: 282,
          text: "28 kun (2)",
        },
        {
          value: 194,
          text: "19 soat (4)",
        },
        {
          value: 34,
          text: "3 kun (4)",
        },
        {
          value: 74,
          text: "7 kun (4)",
        },
        {
          value: 144,
          text: "14 kun (4)",
        },
        {
          value: 284,
          text: "28 kun (4)",
        },
      ],
      money: {},
      headers: [
        {
          text: "T/r",
          sortable: false,
          value: "id",
          class: "primary--text",
        },
        {
          text: "FIO",
          sortable: false,
          value: "first_name",
          class: "primary--text",
        },
        {
          text: "User",
          sortable: false,
          value: "user_id",
          class: "primary--text",
        },
        {
          text: "Telefon",
          sortable: false,
          value: "phone_number",
          class: "primary--text",
        },
        {
          text: "Hudud",
          sortable: false,
          value: "region",
          class: "primary--text",
        },
        {
          text: "Promokodlar soni",
          sortable: false,
          value: "promocode_count",
          class: "primary--text",
        },
        
        // {
        //   text: "Amallar",
        //   value: "actions",
        //   sortable: false,
        //   class: "primary--text",
        //   align: "center",
        // },
      ],
    };
  },

  mounted() {
    let date = new Date().toLocaleDateString();
    // let time = new Date().toLocaleTimeString();
    this.today = date;
    this.getUser();
  },

  methods: {
    // addArray() {
    //   let arr = [];
    //   for (let i = 1; i < 6; i++) {
    //     if (eval("this.row" + i).length > 0) {
    //       arr.push(eval("this.row" + i));
    //     }
    //   }
    //   // arr.push(this.row, this.row2);
    //   this.checked = arr;
    // },
    updatePage($event) {
        this.getUser();
      },
      updatePerPage($event) {
        this.getUser();
      },
    getUser() {
      this.$http
        .post("/usersList", {
          pagination: this.dataTableOptions,
        })
        .then(({ data }) => {
          let date = new Date().toISOString().slice(0, 10);
          this.alluserscount = data.total;
          this.users = data.data.filter((v) => {
            if (v.role == 0) {
              return v;
            }
          });
          this.admins = data.data.filter((v) => {
            if (v.role == 1) {
              return v;
            }
          });
          this.adminuserscount = this.admins.length;
          this.userscount = this.alluserscount - this.adminuserscount;
          this.server_items_length = data.total;
          this.from = data.from;
          this.loaded = true;
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessage();
        });
    },
    newAction() {
      this.money = {
        id: Date.now(),
        name: null,
        reg_date: null,
        exp_date: null,
      };
      this.addMoneyModal = true;
    },
    addAction() {
      this.$http
        .post("/addAction", {
          money: this.money,
        })
        .then(({ data }) => {
          this.getUser();
          this.addMoneyModal = false;
          this.showSuccessMessage("Malumotlar qo'shildi", 3000);
        })
        .catch((error) => {
          console.log(error);
          // this.showErrorMessage();
        });
    },

    deleteAction(item) {
      this.$http
        .delete("/deleteAction/" + item.id)
        .then(({ data }) => {
          this.getUser();

          this.showSuccessMessage("Action has been deleted");
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessage();
        });
    },
    sendMessage(item) {
      this.$http
        .get("/sendMessage")
        .then(({ data }) => {
          // this.getUser();

          this.showSuccessMessage("Xabaringiz foydalanuvchilarga yuborildi");
          this.getUser();
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessage();
        });
    },
    refreshPromoCount(item) {
      this.$http
        .get("/getuserspromocodecount")
        .then(({ data }) => {
          // this.getUser();

          this.showSuccessMessage("Foydalanuvchilar promokodlar soni yangilandi");
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessage();
        });
    },

    edit(item) {
      this.addMoneyModal = true;
      this.money = Object.assign({}, item);
    },
    changeStatus(item) {
      this.$http
        .post("/changeStatus", {
          item: item,
        })
        .then(({ data }) => {
          this.getUser();

          this.showSuccessMessage("Action has been deleted");
        })
        .catch((error) => {
          this.showErrorMessage();
        });
    },
  },
};
</script>

<style lang="css" scoped>
.input-errors >>> .v-input__control > .v-input__slot:before {
  border-color: #f56c6c !important;
}
.income-text {
  color: green;
}
.primary-text {
  color: blue;
}
.expance-text {
  color: red;
}
.today-text {
  color: orange;
}
.header-text {
  color: white;
  background-color: rgb(223, 221, 221);
}
</style>
