<template>
    <div>
      <v-layout wrap justify-center align-center>
        <v-flex xs12 sm12 md8 class="px-4">
          <div class="d-flex align-center justify-center">
            <v-spacer></v-spacer>
            <h2
              class="text-center mr-5 font-weight-bold light-blue--text text--darken-4 my-4"
            >
              {{ today }}
            </h2>
  
            <v-spacer></v-spacer>
            <v-card-title>Promokodlar ro'yxati</v-card-title>
            <!-- <v-btn color="success" class="ma-2"  large @click="sendMessage()">
              <v-icon>mdi-send</v-icon> Xabar yuborish
            </v-btn>-->
            <v-btn color="primary" large @click="newAction()">
              Yangi qo'shish
            </v-btn> 
            <v-btn class="ma-2" color="success" large @click="excelUploadModal=true">
              Excel
            </v-btn> 
          </div>
  
          <v-progress-linear
            v-if="!loaded"
            color="primary"
            indeterminate
            rounded
            height="4"
          ></v-progress-linear>
  
          <div class="d-flex justify-space-around">
            <div class="d-flex flex-column align-center">
              <p class="income-text mb-n1" style="font-size: 32px">
                {{ promocodeCount ? promocodeCount.approved : '' }} ta
              </p>
              <p class="income-text">To'langanlari</p>
            </div>
            <div class="d-flex flex-column align-center">
              <p class="expance-text mb-n1" style="font-size: 32px">
                {{ promocodeCount ? promocodeCount.cancel : ''}} ta
              </p>
              <p class="expance-text">Bekor qilingan</p>
            </div>
            <div class="d-flex flex-column align-center">
              <p class="today-text mb-n1" style="font-size: 32px">
                {{ promocodeCount ? promocodeCount.process : '' }} ta
              </p>
              <p class="today-text">Jarayonda</p>
            </div>
            <div class="d-flex flex-column align-center">
              <p class="overall-text mb-n1" style="font-size: 32px">
                {{ promocodeCount ? promocodeCount.all : '' }} ta
              </p>
              <p class="overall-text">Jami</p>
            </div>
          </div>
          <div class="d-flex flex-wrap justify-center">
            <v-text-field label="Qidiruv" v-model="search" @keyup.enter="getUser()"></v-text-field>
            <v-card class="ma-2" xs12 sm8 md6 min-width="100%">
              <v-tabs v-model="tab" background-color="primary" dark fixed-tabs>
                <v-tab v-for="item in tabsContent" :key="item.value">
                  {{ item.text }}
                </v-tab>
              </v-tabs>
  
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-card flat>
                    <v-data-table
                      :headers="headers"
                      :items="paymentWaiting"
                      item-key="id"
                      :options.sync="dataTableOptions"
                      :server-items-length="server_items_length"
                      class="elevation-1"
                      :disable-pagination="true"
                      @update:page="updatePage"
                      @update:items-per-page="updatePerPage"
                      :footer-props="{
                        itemsPerPageOptions: [20, 50, 100],
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-arrow-left',
                        nextIcon: 'mdi-arrow-right',
                      }"
                    >
                    <template v-slot:item.id="{item}">
                      {{
                        paymentWaiting
                        .map(function(x) {
                        return x.id;
                        })
                        .indexOf(item.id) + from
                      }}
                    </template>
                    <template v-slot:item.status="{item}">
                        <v-btn
                          small
                          text
                          :color="statuses.filter((v)=>v.value==item.status)[0].color"
                        >
                        {{ statuses.filter((v)=>v.value==item.status)[0].text }}
                        </v-btn>
                      <!-- <p :style="{'background-color': statuses.filter((v)=>v.value==item.status)[0].color}">
                          {{ statuses.filter((v)=>v.value==item.status)[0].text }}
                      </p> -->
                    </template>
                    <template v-slot:item.updated_at="{item}">
                        
                        {{ item.updated_at ? item.updated_at.substr(0,10) : ''}}  
                        {{ item.updated_at ? item.updated_at.substr(11,5) : ''}}
                    </template>
                      <template v-slot:item.actions="{ item }">
                        <v-btn
                          color="primary"
                          small
                          text
                          @click="editItem(item)"
                        >
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          color="error"
                          small
                          text
                          @click="deleteItem(item)"
                        >
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template></v-data-table
                    >
                  </v-card>
                </v-tab-item>
                <!-- <v-tab-item>
                  <v-card flat>
                    <v-data-table
                      :headers="headers2"
                      :items="admins"
                      :options="dataTableOptions"
                      class="elevation-1"
                      :disable-pagination="true"
                      @update:page="updatePage"
                      @update:items-per-page="updatePerPage"
                      :footer-props="{
                        itemsPerPageOptions: [20, 50, 100],
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-arrow-left',
                        nextIcon: 'mdi-arrow-right',
                      }"
                      >
                      </v-data-table
                    >
                  </v-card>
                </v-tab-item> -->
              </v-tabs-items>
            </v-card>
          </div>
        </v-flex>
      </v-layout>
      <v-dialog
        v-model="addMoneyModal"
        persistent
        max-width="450px"
        @keydown.esc="addMoneyModal = false"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Add Action</span>
            <v-spacer></v-spacer>
            <v-btn color="error" x-small fab @click="addMoneyModal = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col class="pt-0" cols="12">
                  <v-text-field
                    v-model="money.name"
                    label="Name"
                    type="text"
                    solo
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0" cols="12">
                  <v-text-field
                    v-model="money.reg_date"
                    label="Kelgan sana"
                    type="datetime-local"
                    outlined
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
                <!-- <v-col class="pt-0" cols="12">
                  <v-select
                    v-model="money.exp_date"
                    :items="expDates"
                    label="Type"
                    multiple
                    chips
                    solo
                  ></v-select>
                </v-col> -->
              </v-row>
            </v-container>
          </v-card-text>
  
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="green" dark @click="addAction()">Save</v-btn>
            <!-- <v-btn color="green" dark @click="addArray()">Push</v-btn> -->
            <!--                        <v-btn color="red darken-1" dark @click="onClickOutside">{{ $t('close') }}</v-btn>-->
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="excelUploadModal"
        persistent
        max-width="450px"
        @keydown.esc="excelUploadModal = false"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Excel Upload</span>
            <v-spacer></v-spacer>
            <v-btn color="error" x-small fab @click="excelUploadModal = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col class="pt-0" cols="12">
                    <v-file-input
                        v-model="file"
                        append-icon="mdi-microsoft-excel"
                        color="#1f6e43"
                        counter
                        outlined
                        dense
                        hide-details
                        show-size
                        truncate-length="50"
                    ></v-file-input>
                </v-col>
                <!-- <v-col class="pt-0" cols="12">
                  <v-text-field
                    v-model="money.reg_date"
                    label="Kelgan sana"
                    type="datetime-local"
                    outlined
                    dense
                    clearable
                  ></v-text-field>
                </v-col> -->
                <!-- <v-col class="pt-0" cols="12">
                  <v-select
                    v-model="money.exp_date"
                    :items="expDates"
                    label="Type"
                    multiple
                    chips
                    solo
                  ></v-select>
                </v-col> -->
              </v-row>
            </v-container>
          </v-card-text>
  
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="green" dark @click="uploadExcel()">Save</v-btn>
            <!-- <v-btn color="green" dark @click="addArray()">Push</v-btn> -->
            <!--                        <v-btn color="red darken-1" dark @click="onClickOutside">{{ $t('close') }}</v-btn>-->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { validationMixin } from "vuelidate";
  import { maxLength, required } from "vuelidate/lib/validators";
  import messagesMixin from "@/mixins/messagesMixin";
  
  export default {
    name: "Todos",
  
    mixins: [messagesMixin, validationMixin],
  
    data() {
      return {
        today: "",
        tab: "",
        user: null,
        actions: [],
        users: [],
        dataTableOptions: {
          page: 1,
          itemsPerPage: 50,
        },
        page: 1,
        from: 0,
        server_items_length: -1,
        paymentWaiting: [],
        addBalanceModal: false,
        addMoneyModal: false,
        excelUploadModal: false,
        showBalanceStatus: false,
        promocodeCount: null,
        balance: null,
        file: null,
        search: '',
        loaded: false,
        todayCount: 0,
        checkedCount: 0,
        unCheckedCount: 0,
        statuses: [
          {
            value: 0,
            text: "Qoralama",
            color: "grey"
          },
          {
            value: 1,
            text: "Yangi",
            color: "purple"
          },
          {
            value: 101,
            text: "Yangi",
            color: "purple"
          },
          {
            value: 200,
            text: "Jarayonda",
            color: "orange"
          },
          {
            value: 201,
            text: "Jarayonda",
            color: "orange"
          },
          {
            value: 202,
            text: "Bekor qilindi",
            color: "red"
          },
          {
            value: 300,
            text: "To'landi",
            color: "green"
          },
        ],
        tabsContent: [
          {
            value: 0,
            text: "Barcha Promokodlar",
          },
          {
            value: 1,
            text: "Mega Aksiya",
          },
        ],
        money: {},
        headers: [
          {
            text: "T/r",
            sortable: false,
            value: "id",
            class: "primary--text",
          },
          {
            text: "Promokod",
            sortable: false,
            value: "code",
            class: "primary--text",
          },
          {
            text: "Miqdori",
            value: "discount_amount",
            class: "primary--text",
          },
          {
            text: "Mahsulot",
            sortable: false,
            value: "product_id",
            class: "primary--text",
          },
        //   {
        //     text: "User",
        //     sortable: false,
        //     value: "user_id",
        //     class: "primary--text",
        //   },
          {
            text: "Promokod turi",
            sortable: false,
            value: "promocode_type",
            class: "primary--text",
          },
          {
            text: "Seriya raqami",
            sortable: false,
            value: "serial_number",
            class: "primary--text",
          },
          {
            text: "Holati",
            value: "status",
            class: "primary--text",
          },
          {
            text: "Yangilangan",
            value: "updated_at",
            class: "primary--text",
          },
          
          {
            text: "Amallar",
            value: "actions",
            sortable: false,
            class: "primary--text",
            align: "center",
          },
        ],
        headers2: [
          {
            text: "Nomi",
            align: "start",
            sortable: false,
            value: "name",
            class: "primary--text",
          },
          {
            text: "Soni",
            sortable: false,
            value: "examples",
            class: "primary--text",
          },
          {
            text: "Tekshirilish sanasi",
            sortable: false,
            value: "check_date",
            class: "primary--text",
          },
          {
            text: "Muddati",
            sortable: false,
            value: "count",
            class: "primary--text",
          },
          {
            text: "Holati",
            sortable: false,
            value: "status",
            class: "primary--text",
          },
          {
            text: "Amallar",
            value: "actions",
            sortable: false,
            class: "primary--text",
            align: "center",
          },
        ],
      };
    },
  
    mounted() {
      let date = new Date().toLocaleDateString();
      // let time = new Date().toLocaleTimeString();
      this.today = date;
      this.getUser();
      this.getCount();
      // this.getUserInfo();
    },
  
    methods: {
      updatePage($event) {
        this.getUser();
      },
      updatePerPage($event) {
        this.getUser();
      },
      getUser() {
        this.$http
          .post("/getPromocodes", {
            search: this.search,
            pagination: this.dataTableOptions,
          })
          .then(({ data }) => {
            this.paymentWaiting = data.data;
            this.server_items_length = data.total;
            this.from = data.from;
            console.log(data.from);
            // let date = new Date().toISOString().slice(0, 10);
            // this.users = data.filter((v) => {
            //   if (v.role == 0) {
            //     return v;
            //   }
            // });
            // this.admins = data.data.filter((v) => {
            //   if (v.role == 1) {
            //     return v;
            //   }
            // });
            
            this.loaded = true;
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
      },
      getCount(){
        this.$http
          .get("/getPromocodesCount")
          .then(({ data }) => {
            this.promocodeCount = data;
            this.loaded = true;
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
      },
      newAction() {
        this.money = {
          id: Date.now(),
          name: null,
          reg_date: null,
          exp_date: null,
        };
        this.addMoneyModal = true;
      },
      addAction() {
        this.$http
          .post("/addAction", {
            money: this.money,
          })
          .then(({ data }) => {
            this.getUser();
            this.addMoneyModal = false;
            this.showSuccessMessage("Malumotlar qo'shildi", 3000);
          })
          .catch((error) => {
            console.log(error);
            // this.showErrorMessage();
          });
      },
  
      deleteAction(item) {
        this.$http
          .delete("/deleteAction/" + item.id)
          .then(({ data }) => {
            this.getUser();
  
            this.showSuccessMessage("Action has been deleted");
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
      },
      sendMessage(item) {
        this.$http
          .get("/sendMessage")
          .then(({ data }) => {
            // this.getUser();
  
            this.showSuccessMessage("Xabaringiz foydalanuvchilarga yuborildi");
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessage();
          });
      },
  
      edit(item) {
        this.addMoneyModal = true;
        this.money = Object.assign({}, item);
      },
      changeStatus(item) {
        this.$http
          .post("/changeStatus", {
            item: item,
          })
          .then(({ data }) => {
            this.getUser();
  
            this.showSuccessMessage("Action has been deleted");
          })
          .catch((error) => {
            this.showErrorMessage();
          });
      },
      uploadExcel() {
        var formData = new FormData();
        formData.append("file", this.file);
        this.$http
          .post("/upload-excel", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
          .then(({ data }) => {
            this.getUser();
  
            this.showSuccessMessage("Muvaffaqqiyatli yuklandi");
            this.excelUploadModal = false;
          })
          .catch((error) => {
            this.showErrorMessage();
          });
      },
      // getUserInfo() {
      //   this.$http
      //     .get("/getUserLogs")
      //     .then(({ data }) => {
      //       console.log(data);
      //     })
      //     .catch((error) => {
      //       this.showErrorMessage();
      //     });
      // },
    },
  };
  </script>
  
  <style lang="css" scoped>
  .input-errors >>> .v-input__control > .v-input__slot:before {
    border-color: #f56c6c !important;
  }
  .income-text {
    color: green;
  }
  .primary-text {
    color: green;
  }
  .expance-text {
    color: red;
  }
  .today-text {
    color: orange;
  }
  .overall-text {
    color: rgb(136, 17, 233);
  }
  .header-text {
    color: white;
    background-color: rgb(223, 221, 221);
  }
  </style>
  